import { useEffect, useState } from 'react';
import { useMainContext } from '../context/MainContext';
import styles from '../styles/Drawer.module.scss';

export default function Drawer() {
  const { token } = useMainContext();
  const [projects, setProjects] = useState([]);
  const [drawer, setDrawer] = useState({
    scrolling: false,
    startX: null,
    scrollLeft: null
  });
  const _onMouseDown = (event) => {
      const slider = document.getElementById(styles.drawer);
      setDrawer({ scrolling: true, startX: event.pageX - slider.offsetLeft, scrollLeft: slider.scrollLeft });
    },
    _onMouseUp = () => {
      setDrawer({ ...drawer, scrolling: false });
    },
    _onMouseMove = (event) => {
      event.preventDefault();
      if (!drawer.scrolling) return;

      const slider = document.getElementById(styles.drawer);
      const x = event.pageX - slider.offsetLeft;
      const scroll = x - drawer.startX;
      slider.scrollLeft = drawer.scrollLeft - scroll;
    };

  useEffect(() => {
    // Promise.resolve(GetProjects(token)).then((e) => setProjects(e));
    setProjects([
      { id: 1, title: 'Demo 1', description: 'Pre-defined', company_id: 1 }
      // { id: 2, title: 'Demo 2', description: 'Meshes only', company_id: 1 }
    ]);
  }, [token]);

  return (
    <div
      id={styles.drawer}
      className={styles.scroll}
      onMouseDown={_onMouseDown}
      onMouseUp={_onMouseUp}
      onMouseMove={_onMouseMove}
      onMouseLeave={_onMouseUp}
    >
      {projects?.length === 0 && <div id={styles.loader}>{/* <Spinner animation='border' variant='primary' /> */}</div>}
      {projects?.map((project) => {
        return <ProjectBlock key={project.id} project={project} />;
      })}
    </div>
  );
}

function ProjectBlock({ project }) {
  const { token, setCanvasModelInformation, setProjectInformation } = useMainContext();
  const [modelOverlay, setModelOverlay] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className={styles.modelBlock} onMouseEnter={() => setModelOverlay(true)} onMouseLeave={() => setModelOverlay(false)}>
      <strong>{project.title}</strong>
      <span className='pt-2'>{project.description}</span>
      <div className={`${styles.overlay} ${modelOverlay ? '' : 'hidden'}`}>
        <button
          disabled={isLoading}
          className='items-center text-center rounded-md border border-transparent bg-green-500 px-4 py-2 text-sm font-medium text-white shadow-sm
          hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2'
          onClick={() => {
            // setIsLoading(true);
            // Promise.all([GetSettingsFromProject(token, project.id), GetModelsFromProject(token, project.id)])
            //   .then((data) => {
            //     setProjectInformation(data[0]);
            //     setCanvasModelInformation(data[1]);
            //   })
            //   .finally(() => setIsLoading(false));

            setProjectInformation({
              id: project.id,
              title: 'Demo 1',
              description: '',
              setting: {
                id: 1,
                project_id: 1,
                project_type: 'step based',
                is_public: 1,
                laser_text_enabled: 0,
                part_denied_function: 'error',
                laser_config: 'PROCON'
              },
              links: [{ id: 1, rel: 'model' }]
            });
            setCanvasModelInformation([
              {
                id: 1,
                name: 'Bufferkast demo',
                description: '',
                created_at: '2022-10-20T07:19:48.000000Z',
                updated_at: '2022-11-18T15:44:21.000000Z',
                company_id: 1,
                model_asset_category_id: null,
                internal_name: null,
                deleted_at: null,
                image: null,
                has_been_processed: 0,
                pivot: {
                  project_id: 1,
                  model_id: 1
                }
              }
            ]);
          }}
        >
          Select
        </button>
      </div>
    </div>
  );
}

export function scrollTo(id) {
  const step = document.getElementById(`step_${id}`);

  // Needs a small delay because we change the size
  setTimeout(() => {
    step?.scrollIntoView({ behavior: 'smooth', inline: 'center' });
  }, 301);
}
