import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useMainContext } from './MainContext';

const StepBasedContext = createContext();

export function StepBasedContextWrapper({ children }) {
  const { projectInformation } = useMainContext();

  const [MOREFUCKINGSTEPS, setMOREFUCKINGSTEPS] = useState([]);
  useEffect(() => {
    Promise.resolve(import('./MOREFUCKINGSTEPS.json')).then((data) => {
      setMOREFUCKINGSTEPS(data.default);
    });
  }, []);

  // console.log(MOREFUCKINGSTEPS.filter((mfs) => mfs.meshpath.startsWith('D_-_')));

  const [steps, setSteps] = useState([]);
  const [currentPart, setCurrentPart] = useState();
  const [currentPartRefs, setCurrentPartRefs] = useState();
  const [currentStepInfo, setCurrentStepInfo] = useState();
  const value = useMemo(
    () => ({
      steps,
      setSteps,
      currentPart,
      setCurrentPart,
      currentPartRefs,
      setCurrentPartRefs,
      currentStepInfo,
      setCurrentStepInfo
    }),
    [currentPart, currentPartRefs, currentStepInfo, steps]
  );

  useEffect(() => {
    let ID = 0;
    setSteps(
      projectInformation?.id === 1
        ? [
            {
              id: ID++,
              name: 'Voorbereiding',
              changes: [{ meshpath: '20133927', name: '20133927' }],
              description: 'Tijd om te beginnen aan de brandstoftank. Denk goed aan de actiepunten.',
              checklist: [
                { id: 4, value: 'Maak de brandstoftank goed schoon' },
                { id: 2, value: 'Zuig de brandstoftank uit van vuildeeltjes' }
              ]
            },
            {
              id: ID++,
              name: 'Trillingsdemper (20057325)',
              description: 'Monteer de trillingsdemper (20057325) aan de brandstof tank ',
              changes: [{ meshpath: '20057325', name: '20057325' }],
              effect: 'pulse',
              files: [{ id: 0, value: './img/agxeed/1.jpg', type: 'img' }]
            },
            {
              id: ID++,
              name: 'Zeskantbout (20033421)',
              description: 'Draai zeskantbout (20033421) los in de trillingsdemper',
              changes: [{ meshpath: '20033421', name: '20033421' }],
              effect: 'pulse',
              files: [{ id: 0, value: './img/agxeed/1.jpg', type: 'img' }]
            },
            {
              id: ID++,
              name: 'Plug (20053854)',
              description: 'Monteer de plug onder in de brandstof tank',
              changes: [{ meshpath: '20053854', name: '20053854' }],
              effect: 'pulse',
              files: [{ id: 0, value: './img/agxeed/2.jpg', type: 'img' }]
            },
            {
              id: ID++,
              name: 'Brandstofdop (20055686)',
              description: 'Monteer de brandstofdop op de brandstof tank',
              changes: [{ meshpath: '20055686', name: '20055686' }],
              effect: 'pulse',
              files: [{ id: 0, value: './img/agxeed/3.jpg', type: 'img' }],
              checklist: [{ id: 1, value: 'Laat de sleutels in de brandstofdop steken' }]
            },
            {
              id: ID++,
              name: 'Brandstofdop ketting (20055688)',
              description: 'Monteer aan de brandstofdop een ketting (20055688)',
              changes: [{ meshpath: '20055688', name: '20055688' }],
              effect: 'pulse',
              files: [{ id: 0, value: './img/agxeed/4.jpg', type: 'img' }]
            },
            {
              id: ID++,
              name: 'Aanzuigbuis (20053640)',
              description: 'Monteer de aanzuigbuis (20053640) in de brandstoftank ',
              checklist: [{ id: 1, value: 'Vet de o-ring een beetje in met vet!!' }],
              changes: [{ meshpath: '20053640', name: '20053640' }],
              effect: 'pulse',
              files: [{ id: 0, value: './img/agxeed/5.jpg', type: 'img' }]
            },
            {
              id: ID++,
              name: 'Inschroefnippels (20055227) en slangpilaren (20058991)',
              description: 'Demonteer de slangpilaren uit de aanzuigbuis en monteer de nieuwe inschroefnippels en slangpilaren',
              changes: [
                { meshpath: '20055227', name: '20055227' },
                { meshpath: '20055227_1', name: '20055227' },
                { meshpath: '20055227_2', name: '20055227' },
                { meshpath: '20058991', name: '20058991' },
                { meshpath: '20058991_1', name: '20058991' },
                { meshpath: '20058991_2', name: '20058991' }
              ],
              effect: 'pulse',
              files: [{ id: 0, value: './img/agxeed/5.jpg', type: 'img' }],
              checklist: [{ id: 9, value: 'Plak de slangpilaren af met tape' }]
            },
            {
              id: ID++,
              name: 'De gaten van de strip op tappen',
              description: `Monteer met de inbusbouten de strip aan de binnenkant van de koker aan de brandstof tank vast. 
                Monteer de 4 zeskantbouten aan de strip draai deze met de hand vast`,
              checklist: [
                { id: 5, value: 'Strip (20033309)' },
                { id: 6, value: 'Pak 4 zeskantbouten (20033471)' },
                { id: 7, value: 'Pak 3 Inbusbouten (20030709)' }
              ],
              changes: [
                { meshpath: '20033309_1', name: '20033309' },
                { meshpath: '20033471_1', name: '20033471' },
                { meshpath: '20033471_2', name: '20033471' },
                { meshpath: '20033471_3', name: '20033471' },
                { meshpath: '20033471_4', name: '20033471' },
                { meshpath: '20030709_1', name: '20030709' },
                { meshpath: '20030709_2', name: '20030709' },
                { meshpath: '20030709_3', name: '20030709' }
              ],
              effect: 'pulse',
              files: [{ id: 0, value: './img/agxeed/6.jpg', type: 'img' }]
            },
            {
              id: ID++,
              name: 'Stop (20033472)',
              description: 'Monteer de stop in de koker',
              changes: [{ meshpath: '20033472', name: '20033472' }],
              effect: 'pulse',
              files: [{ id: 0, value: './img/agxeed/7.jpg', type: 'img' }]
            },
            {
              id: ID++,
              name: '',
              description: 'Monteer de brandstof tank op de gemaakte pallet en bind deze vast met een spanbandje',
              changes: [],
              files: [{ id: 0, value: './img/agxeed/8.jpg', type: 'img' }]
            }
          ]
        : [
            ...[
              ...MOREFUCKINGSTEPS.map(({ meshpath, name }, i) => {
                return {
                  id: i,
                  name: 'Plaatsing onderdeel',
                  effect: 'pulse',
                  changes: [{ id: 0, meshpath, name }]
                };
              })
            ]
          ]
    );
  }, [MOREFUCKINGSTEPS, projectInformation?.id]);

  return <StepBasedContext.Provider value={value}>{children}</StepBasedContext.Provider>;
}
export function useStepBasedContext() {
  return useContext(StepBasedContext);
}
