import { Box, Sphere } from '@react-three/drei';
import { useMemo } from 'react';
import { Vector3 } from 'three';
import { useHelperContext } from '../../context/HelperContext';

export default function LaserBoxHelper() {
  const { boxes, xAdjustment, yAdjustment } = useHelperContext();
  const isDev = useMemo(() => process.env.NODE_ENV === 'development', []);

  return null;
  return isDev ? (
    <>
      {/* YELLOW -> (0,0) of the world  */}
      <Sphere position={new Vector3()} scale={0.01} material-color={0xf7d038} />

      {/* PURPLE -> (0,0) of the model */}
      <Sphere position={new Vector3(xAdjustment, yAdjustment, 0)} scale={0.01} material-color={0x510670} />

      {/* Position of the part */}
      {boxes.map((b, i) => (
        <group key={i}>
          <Box
            wireframe
            // Do the +(size/2) stuff because three calculates from the center of an object
            position={new Vector3(b.position.x + b.width / 2, b.position.y + b.height / 2, b.position.z)}
            args={[b.width, b.height, b?.depth || 0.01]}
            material-color={b?.color || 'hotpink'}
            material-wireframe
          />

          {/* Draw point of the part */}
          <Box
            wireframe
            position={new Vector3(b.position.x, b.position.y, 0.5)}
            args={[0.01, 0.01, 1]}
            material-color={'blue'}
            material-wireframe
            visible={false}
          />
        </group>
      ))}
    </>
  ) : null;
}
