import { useGLTF } from '@react-three/drei';
import { Suspense, useEffect, useMemo, useRef } from 'react';
import { BufferGeometry, Mesh } from 'three';
import { acceleratedRaycast, computeBoundsTree, disposeBoundsTree } from 'three-mesh-bvh';
import { useHelperContext } from '../../context/HelperContext';
import { useMainContext } from '../../context/MainContext';
import { gridCollision } from '../scanner/LaserAdjuster';

BufferGeometry.prototype.computeBoundsTree = computeBoundsTree;
BufferGeometry.prototype.disposeBoundsTree = disposeBoundsTree;
Mesh.prototype.raycast = acceleratedRaycast;

const url = `brandstoftank.glb`;
useGLTF.preload(url);

export default function ModelLoader({ groupRef }) {
  const { canvasModelInformation } = useMainContext();

  return (
    <>
      <group ref={groupRef}>
        {canvasModelInformation?.map((model, i) => (
          <Suspense key={i}>
            <Model id={model.id} />
          </Suspense>
        ))}
      </group>
    </>
  );
}

function Model({ id }) {
  const { setXAdjustment, setYAdjustment } = useHelperContext();
  const { projectProgress, projectChecklist, token, setFinishedLoading } = useMainContext();
  const mesh = useRef();

  // const url = `${process.env.REACT_APP_API}/viewer/model_assets/download/${id}/`;
  const { scene } = useGLTF(url, true, undefined, (loader) => loader.setRequestHeader({ Authorization: `Bearer ${token}` }));
  scene.traverse((child) => child?.geometry?.computeBoundsTree());
  const copiedModel = useMemo(() => scene.clone(), [scene]);

  // Set model to empty
  useEffect(() => {
    copiedModel.traverse((child) => {
      if (projectChecklist?.find((t) => child.name === t?.mesh)) {
        child.visible = false;
      }
    });
  }, [copiedModel, projectChecklist]);

  useEffect(() => {
    copiedModel.traverse((child) => {
      const checklistItem = projectChecklist?.find((t) => child.name === t?.mesh);
      const checklistItemProgressed = projectProgress?.find((pp) => pp.import_tags_model_id === checklistItem?.id);
      if (checklistItemProgressed) {
        child.visible = checklistItemProgressed.status === 'done';
      }
    });
  }, [copiedModel, projectChecklist, projectProgress]);

  useEffect(() => {
    if (localStorage.getItem('model0point') === 'true') {
      const { x, y } = gridCollision(copiedModel);
      setXAdjustment(x);
      setYAdjustment(y);

      copiedModel.position.setX(x);
      copiedModel.position.setY(y);
    }
  }, [setXAdjustment, setYAdjustment, copiedModel]);

  useEffect(() => {
    if (scene) setFinishedLoading((fl) => fl + 1);
  }, [setFinishedLoading, scene]);

  // GET MORE FUCKING STEPS
  useEffect(() => {
    const array = [];
    if (copiedModel) {
      copiedModel.traverse((child) => {
        if (child.isMesh) array.push({ meshpath: child.name, name: child.parent.name });
        // else console.log(child.name);
      });
    }
    // console.log(array);
  }, [copiedModel]);

  return (
    <primitive
      ref={mesh}
      name='MODEL'
      object={copiedModel}
      dispose={null}
      scale={1}
      rotation-y={-Math.PI / 2}
      onClick={(e) => {
        // e.stopPropagation();
        if (e.object.visible) console.log(e.object.name);
      }}
    />
  );
}
export function highlightElement(element) {
  const materialEffect = (mesh) => {
    mesh.visible = true;

    if (mesh?.isMesh) {
      if (mesh.oldMesh) mesh.material = mesh.oldMesh;

      const oldMesh = mesh.material.clone();
      const m = mesh.material.clone();

      m.emissive?.setHex('0x04cc58');
      m.color.setHex('0x04cc58');
      m.emissiveIntensity = 0.5;
      m.opacity = 0.5;
      m.transparent = true;
      mesh.material = m;
      mesh.oldMesh = oldMesh;
    }
  };

  element.traverse((e) => materialEffect(e));
}
export function unHighlightElement(element, hide = false) {
  element.visible = !hide;
  element.traverseVisible((e) => {
    if (e?.isMesh && e?.oldMesh) {
      e.material = e.oldMesh;
      e.visible = !hide;
    }
  });
}
