import { useEffect, useState } from 'react';
import { useMainContext } from '../context/MainContext';
import '../styles/App.css';
import Drawer from './Drawer';
import Menu from './Menu';
import ProgressBar from './ProgressBar';
import Sidebar from './Sidebar';
import Canvas from './ThreeD/Canvas';
import AuthCheck from './auth/AuthCheck';
import BackgroundScanner from './scanner/BackgroundScanner';
import StepDrawer from './step-based/StepDrawer';

export default function App() {
  const { token, canvasModelInformation, projectInformation } = useMainContext();
  const [isAuthenticated, setIsAuthenticated] = useState(true)

  const stepBased = projectInformation?.setting?.project_type === 'step based';

  useEffect(() => {
    if (token) setIsAuthenticated(true)
  }, [token])

  if (!isAuthenticated) return <AuthCheck />
  return (
    <>
      <Menu />

      <div style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}>
        <Canvas />
        {!canvasModelInformation ? <Drawer /> : null}
        {canvasModelInformation && stepBased ? <StepDrawer /> : null}
      </div>
      {canvasModelInformation ? <>
        <ProgressBar />
        <Sidebar />
        <BackgroundScanner />

        {/* {stepBased ? <StepObjectsViewer /> : null} */}
      </> : null}
    </>
  );
}

